// core
import {mapGetters} from 'vuex';

// components
import Layout from '@/Views/_Layout/index.vue';
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';
import InterseptedImage from '@/Views/_Components/Helpers/InterseptedImage/index.vue';
import Reader from '@/Views/_Components/Reader/index.vue';

// skeleton
import Skeleton from '@/Views/_Skeleton/Magazine/index.vue';

// helpers
import Settings from '@/config/Settings';
import {getInArray} from '@/Helpers/Misc';

// icons
import {HeartIcon, XIcon} from 'vue-feather-icons';
import marked from "marked";

export default {
  name: 'Book',

  data() {
    return {
      reading: false
    };
  },

  components: {
    'oston-layout': Layout,
    'oston-help-menu': HelpMenu,
    'oston-intersepted-image': InterseptedImage,
    'oston-reader': Reader,

    // skeleton
    'oston-skeleton': Skeleton,

    // icons
    'feather-icon-fav': HeartIcon,
    'feather-icon-close': XIcon
  },

  mounted() {
    const {id, slug} = this.$route.params;

    if (!id || !slug) {
      this.$router.push({name: 'Home'});
    }

    window.scrollTo({
      left: 0,
      top: 0
    });

    Settings.title(this.magazine.name);
    _.controller('reader').reset();
  },

  methods: {
    compiledMarkdown: function (magazine) {
      return marked(magazine.description);
    },
    read: function (page = 0) {
      this.reading = true;
      _.controller('loading').set('reading', false);

      _.controller('reader').set({
        magazine: this.magazine,
        page: page,
        totalPages: this.pages.length,
        content: this.pages[page],
        notify: true
      });
    }
  },

  computed: mapGetters({
    magazine: 'MagazineModel/magazine',
    pages: 'MagazineModel/pages',
    loading: 'LoadingModel/loading'
  })
};
